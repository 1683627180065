import React from 'react'
import { graphql, Link } from 'gatsby'
import {get, chunk, isEqual} from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import styles from '../scss/page.scss'
import { Tab, Tabs, Nav } from 'react-bootstrap'
import { queryParamsFromString, stringFromQueryParams, triggerEvent } from '../helpers' 
import {
  InstantSearch,
  Index,
  SearchBox,
  Hits,
  Configure,
  Pagination,
  InfiniteHits,
  connectStateResults
} from 'react-instantsearch-dom'

import InlineHit from '../components/search/hits/inline'
import HitsList from '../components/search/hits/list'

import algoliasearch from 'algoliasearch/lite'

import 'instantsearch.css/themes/algolia.css'

import { ALGOLIA_INDICES, ALGOLIA_GA_EVENTNAME, ALGOLIA_DELAY, ALGOLIA_GA_PROPNAME } from "../constants"
  
const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY, {
    _useRequestCache: true,
  }
)

// This will prevent search when query is empty
// https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/
const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query.trim())) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  }
}


class SearchPage extends React.Component {  
  constructor(props) {
    super(props)
    
    const query = queryParamsFromString(this.props.location.search)
    
    if ('q' in query) {
      query.query = query.q
      delete query.q
    }
    
    query.query = query.query || ''
    query.currentTab = ALGOLIA_INDICES[0]
    
    this.state = {
      searchState: query,      
      stats: {
        [ALGOLIA_INDICES[0]]: 0,
        [ALGOLIA_INDICES[1]]: 0,
        [ALGOLIA_INDICES[2]]: 0,
      }
    }    
    
    this.onSearchStateChange = this.onSearchStateChange.bind(this);
    this.createURL = this.createURL.bind(this);
    this.triggerSearchAnalyticEvent = this.triggerSearchAnalyticEvent.bind(this)
    
    const title = query.query ? 'Search | Honest Paws®' : `Results For: ${query.query} | Honest Paws®`;
    
    this.seo = {
      title: title,
      meta: {
        title: title,
      },
      children: [
        <link rel="canonical" href='https://www.honestpaws.com/search/' key='canonical-url'  />
      ],
      disallowIndexing: true
    }
  }
  
  componentDidMount() {
    // This will just make sure component re-renders search when query is set in the url (workaround for Algolia Bug)
    setTimeout(() => {
      this.forceUpdate()
    }, 500)
  }
  
  /**
   * Sets state query
   * @param {Object} query
   */
  onSearchStateChange = searchState => {
    // console.log(searchState)
    this.setState({searchState});
    
    this.triggerSearchAnalyticEvent(searchState.query)
  };
  
  /**
   * Triggers Analytics Event
   * @param  {string} query
   * @return {void}
   */
  triggerSearchAnalyticEvent(query) {
    if (this.searchAnalyticTimeout) {
      clearTimeout(this.searchAnalyticTimeout)
    }
    this.searchAnalyticTimeout = setTimeout(() => {
      if (query) {
        triggerEvent(ALGOLIA_GA_EVENTNAME, {
          [ALGOLIA_GA_PROPNAME]: query
        })
      }
    }, ALGOLIA_DELAY)
  }
  
  /**
   * Creates url from search's query
   * @param  { Object } state
   * @return { String }
   */
  createURL = state => `?${stringFromQueryParams(state)}`;
  
  render() {
    let statsTimeout = null
    const AllResults = connectStateResults(({ searchState, allSearchResults, children }) => {
      let currentTab = null
      let hasResults = false
      let stats = {
        [ALGOLIA_INDICES[0]]: 0,
        [ALGOLIA_INDICES[1]]: 0,
        [ALGOLIA_INDICES[2]]: 0,
      }
      if (allSearchResults) {
      if (allSearchResults) {
        for (let index in allSearchResults) {
          if (allSearchResults[index]) {
            stats[index] = allSearchResults[index].nbHits
            hasResults = hasResults || allSearchResults[index].nbHits > 0
          }
        }
      }
      }
      
      if (!isEqual(this.state.stats, stats)) {
        if (statsTimeout) {
          clearTimeout(statsTimeout)
        }
        statsTimeout = setTimeout(() => {
          this.setState({
            stats
          })
        }, 100)
      }
      
      return (!hasResults || !searchState.query) ? (
        <>
          <div className="no-results">{ searchState.query ? `No results have been found for ${searchState.query}` : ''}</div>
          {
            ALGOLIA_INDICES.map(index => (
              <Index indexName={index} key={index} />
            ))
          }
        </>
      ) : ''
    })
    const hitsPerPage = 10
    const paginationTranslations = {
      next: "Next",
      previous: "Prev"
    }
    return (
      <Layout containerClassName={`page page-search`} location={ this.props.location }>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary page-header text-center px-4">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">Results for: { this.state.searchState.query } </h1>       
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="container page-content-container text-center">
            <InstantSearch 
              indexName="Products" 
              searchClient={searchClient} 
              searchState={this.state.searchState}
              onSearchStateChange={ this.onSearchStateChange } >              
              <Configure distinct={1} hitsPerPage={hitsPerPage} typoTolerance={false} />
              <SearchBox showLoadingIndicator />
              <div className="search-results"> 
                <AllResults />
                <div id='results-tabs'>
                  <Tab.Container defaultActiveKey={ ALGOLIA_INDICES[0] } id='results-tabs'>
                    <Nav variant='tabs' title="Navigate Search Results">
                      {
                        ALGOLIA_INDICES.map(index => (
                          <Nav.Item key={index}>
                            <Nav.Link eventKey={index}>
                              <span className=''>{index}</span>
                              <span className='badge'>{this.state.stats[index]}</span>
                            </Nav.Link>
                          </Nav.Item>
                        ))
                      }
                    </Nav>
                    <Tab.Content>
                      {
                        ALGOLIA_INDICES.map(index => (
                          <Tab.Pane eventKey={index} key={index}>  
                            <Index indexName={index}>
                              <HitsList />
                              <Pagination showFirst={false} totalPages={10} padding={1} translations={paginationTranslations} />
                            </Index>
                          </Tab.Pane>
                        ))
                      }
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </InstantSearch>
          </div>
        </section>
      </Layout>
    )
  }
}

export default SearchPage

export const SearchQuery = graphql`
  query SearchQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
  }
`